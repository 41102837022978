import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextArea from '../custom/text-area'

const FilterNodeSettings = ({ toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [sourceName, setSourceName] = useState('')
	const [destinationPath, setDestinationPath] = useState('')
	const [condition, setCondition] = useState('')

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setSourceName(node?.data?.sourceName || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setCondition(node?.data?.condition || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!sourceName) {
			toast('Пожалуйста введите название исходного массива', { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast('Пожалуйста введите массив назначения', { type: 'error' })
			return
		}

		if (!condition) {
			toast('Пожалуйста введите условие для фильтрации', { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, condition, description, sourceName, destinationPath } })
	}, [condition, description, destinationPath, node, sourceName, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title}
				description={componentsInfo[node.type].description}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder='Введите описание компонента'
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.row}>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>Исходный массив</Typography>
					<TextField fullWidth placeholder='Например: source' value={sourceName} onChange={e => setSourceName(e.target.value)} />
				</Box>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>Массив назначения</Typography>
					<TextField fullWidth placeholder='Например: target' value={destinationPath} onChange={e => setDestinationPath(e.target.value)} />
				</Box>
			</Box>

			<Box sx={styles.row}>
				<Box sx={styles.w100}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>Условие для фильтрации</Typography>
					<TextArea
						rows={8}
						placeholder={`this.item.type === 'personal'`}
						value={condition}
						onChange={e => setCondition(e.target.value)}
					/>
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>Сохранить</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2
	},
	w50: {
		width: 'calc(50% - 8px)'
	},
	w100: {
		width: '100%'
	},
	title: {
		my: 1.25
	},
	divider: {
		mt: 2,
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		mt: 2
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default FilterNodeSettings