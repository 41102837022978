import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Draggable from 'react-draggable'
import { useReactFlow, useViewport } from '@xyflow/react'

import StartNodeSettings from '../nodes/start-settings'
import ApiNodeSettings from '../nodes/api-settings'
import CheckParamsNodeSettings from '../nodes/check-params-settings'
import RunAPINodeSettings from '../nodes/run-api-settings'
import CheckConditionsNodeSettings from '../nodes/check-conditions-settings'
import MapperNodeSettings from '../nodes/mapper-settings'
import FilterNodeSettings from '../nodes/filter-settings'
import SaveVariablesNodeSettings from '../nodes/save-variables-settings'
import ResultNodeSettings from '../nodes/result-settings'
import ChatAINodeSettings from '../nodes/chat-ai-settings'
import { componentsInfo } from '../../helpers/constants'

const Settings = ({ toast, open, node, nodes, update, cancel }) => {
	const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })
	const { flowToScreenPosition } = useReactFlow()
	const { zoom } = useViewport()

	useEffect(() => {
		const handleWindowResize = () => {
			setSize({ width: window.innerWidth, height: window.innerHeight })
		}

		window.addEventListener('resize', handleWindowResize)
		return () => { window.removeEventListener('resize', handleWindowResize) }
	}, [])

	const defaultPosition = useCallback(() => {
		const nodeWidth = node.measured.width * zoom
		const nodeHeight = node.measured.height * zoom
		const { x: nodeX, y: nodeY } = flowToScreenPosition(node.position)
		const popupWidth = componentsInfo[node.type].width
		const popupHeight = componentsInfo[node.type].height

		let popupX = nodeX + nodeWidth + 20
		let popupY = nodeY

		if (popupX + popupWidth > size.width) {
			popupX = nodeX - popupWidth - 20
			if (popupX < 0)
				popupX = (size.width - popupWidth) / 2
		}

		if (popupY + popupHeight > size.height) {
			popupY = nodeY - popupHeight + nodeHeight
			if (popupY < 0)
				popupY = (size.height - popupHeight) / 2
		}

		return { x: popupX, y: popupY }
	}, [flowToScreenPosition, node, size, zoom])

	return <>
		{open && <Box sx={styles.main}>
			<Draggable defaultPosition={defaultPosition()} handle='div.drag-handle'>
				<Box sx={{
					...styles.container,
					width: componentsInfo[node.type].width,
					height: componentsInfo[node.type].height,
				}}>
					{node?.type === 'start' && <StartNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
					{node?.type === 'api' && <ApiNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}

					{node?.type === 'checkParams' && <CheckParamsNodeSettings toast={toast} node={node} start={nodes.find(nd => nd.type === 'start')} update={update} cancel={cancel} />}
					{node?.type === 'runScenario' && <RunAPINodeSettings toast={toast} node={node} api={nodes.filter(nd => nd.type === 'api')} update={update} cancel={cancel} />}
					{node?.type === 'checkConditions' && <CheckConditionsNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
					{node?.type === 'mapper' && <MapperNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
					{node?.type === 'filter' && <FilterNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
					{node?.type === 'save_variables' && <SaveVariablesNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
					{node?.type === 'getResult' && <ResultNodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
					{node?.type === 'chatAI' && <ChatAINodeSettings toast={toast} node={node} update={update} cancel={cancel} />}
				</Box>
			</Draggable>
		</Box>}
	</>
}

const styles = {
	main: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 1005
	},
	container: {
		background: theme => theme.palette.primary.contrastText,
		borderRadius: '24px',
		border: theme => `1px solid ${theme.palette.primary.divider}`,
		boxShadow: theme => `0px 4px 50px 0px ${theme.palette.primary.black}33`,
		display: 'flex',
		flexDirection: 'column'
	}
}

export default Settings