import React from 'react'
import { Box, Typography } from '@mui/material'

const NodeTitle = ({ title, color }) => (
	<Box sx={styles.main}>
		<Typography variant='moduleTitle' fontWeight='bold' color={color} noWrap>{title}</Typography>
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		pb: 1
	}
}

export default NodeTitle