import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import NodeTitle from '../custom/node-title'
import NodeBody from '../custom/node-body'
import NodeButton from '../custom/node-button'
import NodeHandles from '../custom/node-handles'
import { inputHandles, outputHandles } from '../../helpers/constants'
import { AutoAwesome } from '@mui/icons-material'

const ChatAINode = ({ data }) => {
	const theme = useTheme()

	return <Box sx={{ ...styles.main, opacity: data.focused ? 1 : 0.3 }}>
		<NodeTitle title='ИИ запрос' color='primary.contrastText' />
		<NodeBody
			Icon={data.description ? null : AutoAwesome}
			title={data.description || ''}
			color={theme.palette.primary.black}
		/>
		<NodeHandles
			background={theme.palette.primary.chatAIColor1}
			handles={[...inputHandles, ...outputHandles]}
		/>
		<NodeButton
			innerColor={`linear-gradient(101.17deg, ${theme.palette.primary.chatAIColor1} 0%, ${theme.palette.primary.chatAIColor2} 100%)`}
			outerColor={theme.palette.primary.contrastText}
		/>
	</Box>
}

const styles = {
	main: {
		width: 240,
		minHeight: 80,
		background: theme => `linear-gradient(101.17deg, ${theme.palette.primary.chatAIColor1} 0%, ${theme.palette.primary.chatAIColor2} 100%)`,
		border: theme => `1px solid ${theme.palette.primary.black}33`,
		borderRadius: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
		p: 1
	}
}

export default ChatAINode