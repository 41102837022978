import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { AddRounded } from '@mui/icons-material'

const NodeButton = ({ innerColor, outerColor }) => {
	const styles = useCallback(() => {
		return {
			outer: {
				width: 32,
				height: 32,
				mt: 1,
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: outerColor,
				boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
				cursor: 'pointer'
			},
			inner: {
				width: 16,
				height: 16,
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: innerColor
			},
			icon: {
				color: outerColor,
				fontSize: 16
			}
		}
	}, [innerColor, outerColor])

	return <Box sx={styles().outer} className='edit-button'>
		<Box sx={styles().inner} className='edit-button'>
			<AddRounded sx={styles().icon} className='edit-button' />
		</Box>
	</Box>
}

export default NodeButton