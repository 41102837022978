import {
	AccountTreeRounded,
	AutoAwesome,
	FactCheck,
	Public,
	RocketLaunch,
	Save,
	Storage,
	SwapCalls,
	Tune,
	Webhook
} from '@mui/icons-material'
import { Position } from '@xyflow/react'

export const components = [
	{
		title: 'Сценарии',
		items: [
			{
				title: 'Старт',
				Icon: RocketLaunch,
				color: '#7A5AF8',
				type: 'start',
				help: 'Начальный шаг, который активирует процесс обработки данных. Здесь пользователь выбирает, какую операцию выполнить.'
			},
			{
				title: 'API запрос',
				Icon: Webhook,
				color: '#EE46BC',
				type: 'api',
				help: 'API-сценарии взаимодействуют между системойинтеграции IntellecDialog и внешними сервисами. Сценарии содержат вебхуки для отправки и получения данных, а также действия, которые определяют последовательность выполнения задач.'
			}
		]
	},
	{
		title: 'Команды',
		items: [
			{
				title: 'Проверка',
				Icon: FactCheck,
				color: '#525866',
				type: 'checkParams',
				help: 'Проверяет, ввёл ли пользователь обязательные параметры (например, дату и время занятия)'
			},
			{
				title: 'Запуск API',
				Icon: Public,
				color: '#525866',
				type: 'runScenario',
				help: 'Запускает API-запрос'
			},
			{
				title: 'Условия',
				Icon: SwapCalls,
				color: '#525866',
				type: 'checkConditions',
				help: 'Проверяет условия для перехода на следующий шаг. Например, если введены все параметры, продолжить процесс'
			},
			{
				title: 'Mapper',
				Icon: AccountTreeRounded,
				color: '#525866',
				type: 'mapper',
				help: 'Преобразует или форматирует данные (массив), чтобы их можно было использовать на следующем шаге'
			},
			{
				title: 'Фильтрация',
				Icon: Tune,
				color: '#525866',
				type: 'filter',
				help: 'Отбирает только те данные, которые соответствуют заданным условиям. Например, фильтрация только персональных занятий.'
			},
			{
				title: 'Сохранение',
				Icon: Save,
				color: '#525866',
				type: 'save_variables',
				help: 'Сохраняет данные, полученные на предыдущих шагах, для дальнейшего использования'
			},
			{
				title: 'Результат',
				Icon: Storage,
				color: '#525866',
				type: 'getResult',
				help: 'Отправляет пользователю текстовый ответ'
			},
			{
				title: 'ИИ запрос',
				Icon: AutoAwesome,
				color: '#525866',
				type: 'chatAI',
				help: 'Отправляет запрос в ChatGPT и сохраняет полученный ответ.'
			}
		]
	}
]

export const roles = [
	{ id: 'system', name: 'Системный промпт' },
	{ id: 'user', name: 'Промпт пользователя' },
	{ id: 'assistant', name: 'Ответ ассистента' }
]

export const propTypes = [
	{ id: 'string', name: 'String' },
	{ id: 'number', name: 'Number' },
	{ id: 'array', name: 'Array' },
	{ id: 'object', name: 'Object' }
]

export const methods = [
	{ id: 'GET', name: 'GET' },
	{ id: 'POST', name: 'POST' },
	{ id: 'PUT', name: 'PUT' },
	{ id: 'DELETE', name: 'DELETE' }
]

export const contentTypes = [
	{ id: 'application/json', name: 'application/json' },
	{ id: 'application/x-www-form-urlencoded', name: 'application/x-www-form-urlencoded' }
]

export const requiredEntryTypes = [
	{ id: 'headers', name: 'Заголовки' },
	{ id: 'cookies', name: 'Куки' },
	{ id: 'body', name: 'Тело' },
	{ id: 'queryParams', name: 'Параметры' }
]

export const conditionTypes = [
	{ id: 'update_auth_header', name: 'update_auth_header' },
	{ id: 'update_auth_query', name: 'update_auth_query' },
	{ id: 'header', name: 'header' }
]

export const inputHandles = [
	{ id: 'target-1', type: 'target', position: Position.Top },
	{ id: 'target-2', type: 'target', position: Position.Left }
]

export const outputHandles = [
	{ id: 'source-1', type: 'source', position: Position.Right },
	{ id: 'source-2', type: 'source', position: Position.Bottom }
]

export const componentsInfo = {
	start: {
		title: 'Старт',
		description: 'Начальный шаг, который активирует процесс обработки данных',
		width: 600,
		height: 720
	},
	api: {
		title: 'API сценарий',
		description: 'Используют вебхуки для обмена данными и выполнения действий',
		width: 600,
		height: 720
	},
	checkParams: {
		title: 'Проверка параметров',
		description: 'Проверяет, ввёл ли пользователь обязательные параметры',
		width: 600,
		height: 720
	},
	runScenario: {
		title: 'Запуск API сценария',
		description: 'Предназначен для инициирования выполнения API сценария',
		width: 500,
		height: 380
	},
	checkConditions: {
		title: 'Проверка условий',
		description: 'Проверяет условия для перехода на следующий шаг',
		width: 600,
		height: 720
	},
	mapper: {
		title: 'Mapper',
		description: 'Преобразует или форматирует массив, чтобы использовать в будущем',
		width: 600,
		height: 720
	},
	filter: {
		title: 'Фильтрация',
		description: 'Отбирает данные, которые соответствуют условиям',
		width: 500,
		height: 526
	},
	save_variables: {
		title: 'Сохранение',
		description: 'Сохраняет данные для дальнейшего использования',
		width: 600,
		height: 720
	},
	getResult: {
		title: 'Результат',
		description: 'Предназначен для подготовки текстового ответа пользователю',
		width: 500,
		height: 465
	},
	chatAI: {
		title: 'ИИ запрос',
		description: 'Отправляет запрос в ChatGPT и сохраняет полученный ответ',
		width: 500,
		height: 526
	}
}

export const formats = [
	{ id: '', name: 'Без формата' },
	{ id: 'date', name: 'Дата' }
]