import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import NodeTitle from '../custom/node-title'
import NodeBody from '../custom/node-body'
import NodeButton from '../custom/node-button'

const ApiNode = ({ data }) => {
	const theme = useTheme()

	return <Box sx={{ ...styles.main, opacity: data.focused ? 1 : 0.3 }}>
		<NodeTitle title='API запрос' color='primary.apiColor1' />
		{data.name && <NodeBody title={data.name} color={theme.palette.primary.apiColor1} />}
		<NodeButton
			innerColor={theme.palette.primary.contrastText}
			outerColor={theme.palette.primary.apiColor1}
		/>
	</Box>
}

const styles = {
	main: {
		width: 240,
		minHeight: 80,
		background: theme => `linear-gradient(180deg, ${theme.palette.primary.apiColor2} 0%, ${theme.palette.primary.apiColor3} 100%)`,
		border: theme => `1px solid ${theme.palette.primary.apiColor1}33`,
		borderRadius: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
		p: 1
	}
}

export default ApiNode