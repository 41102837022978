import React from 'react'
import { Box, IconButton } from '@mui/material'
import { AddRounded, FullscreenRounded, LockOpenRounded, LockRounded, RemoveRounded } from '@mui/icons-material'
import { Panel, useReactFlow, useViewport } from '@xyflow/react'

const CustomControl = ({ disabled, setDisabled }) => {
	const { zoomTo, fitView } = useReactFlow()
	const { zoom } = useViewport()

	const zoomIn = () => { zoomTo(zoom >= 1.75 ? 2 : zoom + 0.25) }
	const zoomOut = () => { zoomTo(zoom <= 0.75 ? 0.5 : zoom - 0.25) }

	return (
		<Panel position='top-right' style={styles.panel}>
			<Box sx={styles.container}>
				<IconButton disabled={zoom >= 2} onClick={zoomIn}>
					<AddRounded />
				</IconButton>
				<Box sx={styles.divider} />
				<IconButton disabled={zoom <= 0.5} onClick={zoomOut}>
					<RemoveRounded />
				</IconButton>
			</Box>

			<Box sx={styles.container}>
				<IconButton aria-label='close' onClick={() => { fitView() }}>
					<FullscreenRounded />
				</IconButton>
				<Box sx={styles.divider} />
				<IconButton aria-label='close' onClick={() => { setDisabled(!disabled) }}>
					{disabled ? <LockRounded /> : <LockOpenRounded />}
				</IconButton>
			</Box>
		</Panel>
	)

}

const styles = {
	panel: {
		top: 56,
		margin: '24px 32px',
		width: 40,
		height: 216,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	container: {
		width: '100%',
		height: 96,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRadius: '100px',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
		'& > .MuiIconButton-root > .MuiSvgIcon-root': {
			fontSize: 24,
			color: theme => theme.palette.primary.gray
		},
		'& > .Mui-disabled > .MuiSvgIcon-root': {
			fontSize: 24,
			color: theme => theme.palette.primary.border
		}
	},
	divider: {
		width: 24,
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	}
}

export default CustomControl