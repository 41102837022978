import React from 'react'
import { Box, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'

const NodePort = ({ idx, title, id, color, handleColor }) => (
	<Box sx={{ ...styles.main, backgroundColor: `${color}80` }}>
		<Typography variant='h6' color='primary.contrastText' noWrap>{title}</Typography>
		<Handle
			id={id}
			type='source'
			position={Position.Right}
			style={{
				...styles.port,
				top: 62 + idx * 44,
				backgroundColor: handleColor
			}}
		/>
	</Box>
)

const styles = {
	main: {
		width: '100%',
		height: 40,
		px: 2,
		borderRadius: '10px',
		display: 'flex',
		alignItems: 'center'
	},
	port: {
		width: 16,
		height: 16,
		right: 12,
		border: '4px solid #FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}

export default NodePort