import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetch } from '../../actions/scenarios'
import Login from '../../components/login'

const mapStateToProps = state => {
	return {
		loading: state.scenarios.fetching,
		error: state.scenarios.error
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetch: token => dispatch(fetch(token))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))