import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material'

const Login = ({ error, loading, toast, fetch }) => {
	const [initialized, setInitialized] = useState(false)
	const [token, setToken] = useState('')

	useEffect(() => {
		if (!initialized && localStorage['app-token'])
			fetch(localStorage['app-token'])
		setInitialized(true)
	}, [fetch, initialized])

	
	useEffect(() => {
		if (error)
			toast(error, { type: 'error' })
	}, [error, toast])

	const onLogin = useCallback(() => {
		if (token.length === 0) {
			toast('Пожалуйста введите токен.', { type: 'error' })
			return
		}

		fetch(token)
	}, [fetch, toast, token])

	return (
		<Box component='main' sx={styles.main}>
			{
				loading
					?
					<Box sx={styles.loader}>
						<CircularProgress />
					</Box>
					:
					<Box sx={styles.container}>
						<Box sx={styles.topContainer}>
							<Box sx={styles.logoBox}>
								<img
									src={`/static/images/intellectdialog.png`}
									alt='Logo not loaded'
									style={styles.logo}
								/>
							</Box>

							<Paper elevation={2} sx={styles.paper}>
								<Box sx={styles.title}>
									<Typography variant='h4' fontWeight={600} color='primary.main' sx={styles.mb1}>Добро пожаловать!</Typography>
									<Typography color='secondary.dark'>Войдите в систему, чтобы продолжить использование IntellectDialog.</Typography>
								</Box>
								<Box sx={styles.mt3}>
									<Box sx={styles.mb2}>
										<Typography variant='body2' color='text.primary'>Токен</Typography>
										<TextField
											autoFocus
											fullWidth
											size='small'
											placeholder='Введите токен'
											value={token}
											onChange={e => { setToken(e.target.value) }}
											variant='outlined'
										/>
									</Box>
									<Button variant='contained' color='primary' sx={styles.button} onClick={onLogin}>
										Войти
									</Button>
								</Box>
							</Paper>
						</Box>

						<Box sx={styles.footer}>
							<Typography variant='body2'>© 2024 IntellectDialog</Typography>
						</Box>
					</Box>
			}
		</Box>
	)
}

const styles = {
	main: {
		mx: 12,
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		minHeight: '100%'
	},
	loader: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	},
	container: {
		alignItems: 'start',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		minWidth: 600
	},
	topContainer: {
		pb: 7.5,
		px: 10,
		width: '100%',
		minWidth: 600,
		display: 'flex',
		zIndex: 9999,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	logoBox: {
		mt: 6,
		mb: 3,
		width: '100%',
		minWidth: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	logo: {
		width: 'auto',
		height: 100
	},
	paper: {
		backgroundColor: theme => theme.palette.background.default,
		width: 450,
		p: 4
	},
	footer: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		right: 0,
		height: 60,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		mt: 1,
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	mb1: {
		mb: 1
	},
	mb2: {
		mb: 2
	},
	mt3: {
		mt: 3
	},
	button: {
		width: '100%',
		mb: 2
	}
}

export default Login