import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import NoData from '../custom/no-data'
import TextButton from '../custom/text-button'
import AddButton from '../custom/add-button'
import PropertyItem from '../custom/property-item'
import TextArea from '../custom/text-area'

const FunctionSettings = ({ func, toast, onClose, save }) => {
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [properties, setProperties] = useState([])
	const theme = useTheme()

	useEffect(() => {
		setName(func?.name || '')
		setDescription(func?.description || '')
		setProperties(func?.properties || [])
	}, [func])

	const add = () => {
		setProperties(prps => [...prps, {
			name: '',
			type: 'string',
			required: false,
			format: '',
			example: '',
			description: ''
		}])
	}

	const edit = (idx, key, value) => {
		setProperties(prps => [...prps.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setProperties(prps => [...prps.filter((_, i) => i !== idx)])
	}

	const onSave = useCallback(() => {
		if (!name) {
			toast('Название функции не может быть пустым', { type: 'error' })
			return
		}

		if (!description) {
			toast('Описание функции не может быть пустым', { type: 'error' })
			return
		}

		for (const property of properties) {
			if (property.name.length === 0) {
				toast('Название переменной не может быть пустым', { type: 'error' })
				return
			}

			if (property.example.length === 0) {
				toast('Пример переменной не может быть пустым', { type: 'error' })
				return
			}

			if (property.description.length === 0) {
				toast('Описание переменной не может быть пустым', { type: 'error' })
				return
			}
		}

		save({ name, description, properties })
	}, [description, name, properties, save, toast])

	return <Box sx={styles.main}>
		<DialogTitle
			title={func?.id ? 'Редактирование функции' : 'Добавление функции'}
			description='Выбор сценария при обработке сообщений от пользователя'
			cancel={onClose}
		/>

		<Box sx={styles.row}>
			<Box sx={styles.w100}>
				<Typography variant='h6' fontWeight='bold' sx={styles.title} noWrap>Название</Typography>
				<TextField fullWidth placeholder='appointmentCheck' value={name} onChange={e => setName(e.target.value)} />
			</Box>
		</Box>

		<Box sx={styles.row}>
			<Box sx={styles.w100}>
				<Typography variant='h6' fontWeight='bold' sx={styles.title} noWrap>Описание</Typography>
				<TextArea
					rows={3}
					placeholder='Когда нужно проверить доступность или расписание какого-либо занятия, следует вызвать эту функцию.'
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>
		</Box>

		<Box sx={styles.divider} />

		<Box sx={styles.middle}>
			<Box sx={styles.items}>
				<TextButton title='Переменные' active color={theme.palette.primary.startColor1} onClick={() => { }} />
			</Box>

			<Box sx={styles.body}>
				{
					properties.map((item, key) => (
						<PropertyItem
							key={key}
							title={`Переменная #${key + 1}`}
							divider={key !== 0}
							{...item}
							valueChanged={(k, v) => { edit(key, k, v) }}
							onDelete={() => { remove(key) }}
						/>
					))
				}

				{
					properties.length === 0
						?
						<NoData
							color='startColor1'
							title='Тут пока ничего нет'
							subTitle={`Добавьте переменную и настройте её`}
							add={add}
						/>
						:
						<AddButton color='startColor1' onClick={add} />
				}
			</Box>
		</Box>

		<Box sx={styles.buttonsContainer}>
			<Button variant='contained' color='inherit' sx={styles.button} onClick={onClose}>
				<ArrowBack /> Назад
			</Button>
			<Button variant='contained' color='primary' sx={styles.button} onClick={onSave}>Сохранить</Button>
		</Box>
	</Box>
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		mb: 1.25
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		px: 2,
		mb: 2
	},
	w100: {
		width: '100%'
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	button: {
		width: 'calc(50% - 4px)',
		borderRadius: '100px'
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 348px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		py: 1,
		px: 2
	}
}

export default FunctionSettings