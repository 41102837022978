import React from 'react'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base'
import styled from '@emotion/styled'

const TextareaAutosize = styled(BaseTextareaAutosize)(({ theme }) => ({
	width: '100%',
	minWidth: '100%',
	maxWidth: '100%',
	boxSizing: 'border-box',
	fontFamily: 'Nunito',
	fontSize: 14,
	fontWeight: 400,
	lineHeight: '18px',
	padding: '11px 12px',
	borderColor: theme.palette.primary.divider,
	borderRadius: 12,
	overflow: 'auto !important',
	'::placeholder': {
		color: theme.palette.primary.disabled
	},
	':hover': {
		outline: `1px solid ${theme.palette.primary.black}`
	},
	':focus': {
		outline: `1px solid ${theme.palette.primary.main}`
	},
	':focus-visible': {
		outline: `2px solid ${theme.palette.primary.main}`
	}
}))

const TextArea = ({ placeholder = '', maxRows = null, rows, value, onChange }) => (
	<TextareaAutosize
		style={{ maxHeight: (maxRows || rows) * 18 + 22 }}
		placeholder={placeholder}
		minRows={rows}
		value={value}
		onChange={onChange}
	/>
)

export default TextArea