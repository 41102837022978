import React from 'react'
import { Handle } from '@xyflow/react'

const NodeHandles = ({ handles, background }) => <>
	{handles.map((handle, key) => (
		<Handle key={key}	{...handle} style={{ ...styles.port, background }} />
	))}
</>

const styles = {
	port: {
		width: 16,
		height: 16,
		border: '4px solid #FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}

export default NodeHandles