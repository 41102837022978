import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

import Dropdown from '../custom/dropdown'
import DialogTitle from '../custom/dialog-title'
import { componentsInfo, roles } from '../../helpers/constants'
import TextArea from '../custom/text-area'

const ChatAINodeSettings = ({ toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [role, setRole] = useState('system')
	const [content, setContent] = useState('')
	const [destinationPath, setDestinationPath] = useState('')

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setRole(node?.data?.role || 'system')
			setContent(node?.data?.content || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!content) {
			toast('Промпт не может быть пустым', { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast('Пожалуйста введите значение для сохранения', { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, role, content, destinationPath } })
	}, [content, description, destinationPath, node, role, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title}
				description={componentsInfo[node.type].description}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder='Введите описание компонента'
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.row}>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>Роль</Typography>
					<Dropdown selected={role} items={roles} onChange={item => setRole(item)} />
				</Box>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>Значение для сохранения</Typography>
					<TextField
						fullWidth
						placeholder='Например: response'
						value={destinationPath}
						onChange={e => setDestinationPath(e.target.value)}
					/>
				</Box>
			</Box>

			<Box sx={styles.row}>
				<Box sx={styles.w100}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>Промт</Typography>
					<TextArea
						rows={8}
						placeholder={`'У меня есть список преподавателей, выдаю через запятую: ' + this.instructors.map(t => t.name).join(', ') + '. Дайте мне из списка все самые подходящие по имени или фамилии ' + this.instructor + '. Если преподавателей несколько, дай мне список через запятую. Кроме преподавателей ничего не пиши.'`}
						value={content}
						onChange={e => setContent(e.target.value)}
					/>
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>Сохранить</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2
	},
	w50: {
		width: 'calc(50% - 8px)'
	},
	w100: {
		width: '100%'
	},
	title: {
		my: 1.25
	},
	divider: {
		mt: 2,
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		mt: 2
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default ChatAINodeSettings