import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import NodeTitle from '../custom/node-title'
import NodePort from '../custom/node-port'
import NodeButton from '../custom/node-button'
import NodeHandles from '../custom/node-handles'
import { inputHandles } from '../../helpers/constants'

const CheckConditionsNode = ({ data }) => {
	const theme = useTheme()
	
	return <Box sx={{ ...styles.main, opacity: data.focused ? 1 : 0.3 }}>
		<NodeTitle title='Проверка условий' color='primary.contrastText' />
	
		{data?.conditions?.length > 0 && (
			<Box sx={{ ...styles.container, height: 44 * data.conditions.length - 4 }}>
				{data.conditions.map((condition, key) => (
					<NodePort
						key={key}
						idx={key}
						title={condition.name}
						id={condition.id}
						color={theme.palette.primary.black}
						handleColor={theme.palette.primary.checkConditionsColor}
					/>
				))}
			</Box>
		)}

		<NodeHandles
			background={theme.palette.primary.checkConditionsColor}
			handles={[...inputHandles]}
		/>

		<NodeButton
			innerColor={theme.palette.primary.checkConditionsColor}
			outerColor={theme.palette.primary.contrastText}
		/>
	</Box>
}

const styles = {
	main: {
		width: 240,
		minHeight: 80,
		background: theme => theme.palette.primary.checkConditionsColor,
		border: theme => `1px solid ${theme.palette.primary.black}33`,
		borderRadius: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
		p: 1
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
}

export default CheckConditionsNode