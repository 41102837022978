import React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

const ConditionItem = ({ title, divider, name, value, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>

		<Box sx={styles.container}>
			<Box sx={styles.left}>
				<TextField
					fullWidth
					disabled={name === 'По умолчанию'}
					placeholder='Проверка инструктора'
					value={name}
					onChange={e => valueChanged('name', e.target.value)}
				/>
			</Box>

			<Box sx={styles.right}>
				<TextField
					fullWidth
					disabled={name === 'По умолчанию'}
					placeholder={name === 'По умолчанию' ? 'else' : '!!variables.instructor && !variables.lesson'}
					value={value}
					onChange={e => valueChanged('value', e.target.value)}
				/>
			</Box>

			{
				name === 'По умолчанию'
					?
					<Box sx={styles.emptyIcon} />
					:
					<Box sx={styles.iconContainer} onClick={onDelete}>
						<DeleteForeverRounded sx={styles.icon} />
					</Box>
			}
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	left: {
		width: '30%'
	},
	right: {
		width: 'calc(70% - 60px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	emptyIcon: {
		width: 28
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	}
}

export default ConditionItem