import React, { useState } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material'

const Navbar = ({ logout }) => {
	const [anchorElUser, setAnchorElUser] = useState(null)

	return (
		<Box sx={styles.main}>
			<Box onClick={e => { setAnchorElUser(e.currentTarget) }} sx={styles.avatar}>
				<Box sx={styles.head} />
				<Box sx={styles.body} />
			</Box>
			<Popover
				keepMounted
				sx={styles.popover}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				anchorEl={anchorElUser}
				open={!!anchorElUser}
				onClose={() => { setAnchorElUser(null) }}
			>
				<List sx={styles.paper}>
					<ListItem disablePadding>
						<ListItemButton onClick={logout}>
							<ListItemText primary='Выйти' />
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
		</Box>
	)
}

const styles = {
	main: {
		position: 'fixed',
		right: 32,
		top: 16,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
		zIndex: 3
	},
	avatar: {
		backgroundColor: theme => theme.palette.primary.avatarBox,
		borderRadius: '50%',
		width: 40,
		height: 40,
		cursor: 'pointer',
		overflow: 'hidden',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`
	},
	head: {
		width: 16,
		height: 16,
		mt: 1,
		ml: 1.5,
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRadius: '50%',
		border: theme => `1px solid ${theme.palette.primary.contrastText}`,
		boxShadow: theme => `0px -8px 8px 0px ${theme.palette.primary.contrastText}3D, 0px 4px 4px 0px ${theme.palette.primary.avatar}3D`
	},
	body: {
		width: 32,
		height: 24,
		mt: 0.25,
		ml: 0.5,
		gap: 0,
		background: theme => `radial-gradient(100% 100% at 50% 0%, ${theme.palette.primary.contrastText} 0%, ${theme.palette.primary.contrastText}00 100%)`,
		borderRadius: '50%',
		border: theme => `1px solid ${theme.palette.primary.contrastText}`,
		boxShadow: theme => `0px -8px 8px 0px ${theme.palette.primary.contrastText} inset, 0px 4px 4px 0px ${theme.palette.primary.avatar}3D`
	},
	popover: {
		mt: 1
	},
	paper: {
		width: 150
	},
	icon: {
		fontSize: 48,
		mt: 1,
		color: theme => `${theme.palette.primary.avatar}3D`
	}
}

export default Navbar