import React from 'react'
import { Box, Typography } from '@mui/material'
import { DeleteForeverRounded, ModeEditRounded } from '@mui/icons-material'

const ListItem = ({ title, divider, onEdit, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Box sx={styles.container}>
			<Typography variant='moduleTitle' fontWeight='bold' sx={styles.title} noWrap>{title}</Typography>

			<Box sx={styles.iconContainer} onClick={onEdit}>
				<ModeEditRounded sx={styles.editIcon} />
			</Box>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.deleteIcon} />
			</Box>
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	title: {
		width: 'calc(100% - 88px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	editIcon: {
		fontSize: 28,
		color: theme => theme.palette.warning.main
	},
	deleteIcon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 1
	}
}

export default ListItem