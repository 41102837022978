import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import NoData from '../custom/no-data'
import AddButton from '../custom/add-button'
import VariableItem from '../custom/variable-item'
import TextArea from '../custom/text-area'

const SaveVariablesNodeSettings = ({ toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [values, setValues] = useState([])
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setValues(node?.data?.values || [])
			setId(node?.id)
		}
	}, [id, node])

	const add = () => {
		setValues(vals => [...vals, { source: '', destination: '', saveGlobally: false }])
	}

	const edit = (idx, key, value) => {
		setValues(vals => [...vals.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setValues(vals => [...vals.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		for (const value of values) {
			if (!value.source) {
				toast('Пожалуйста введите исходное значение', { type: 'error' })
				return
			}

			if (!value.destination) {
				toast('Пожалуйста введите значение назначения', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, values } })
	}, [description, node, toast, update, values])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title}
				description={componentsInfo[node.type].description}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder='Введите описание компонента'
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title='Значения' active color={theme.palette.primary.saveVariableColor} onClick={() => { }} />
				</Box>
				<Box sx={styles.body}>
					{
						values.map((item, key) => (
							<VariableItem
								key={key}
								title={`Значение #${key + 1}`}
								divider={key !== 0}
								{...item}
								valueChanged={(k, v) => { edit(key, k, v) }}
								onDelete={() => { remove(key) }}
							/>
						))
					}

					{
						values.length === 0
							?
							<NoData
								color='saveVariableColor'
								title='Тут пока ничего нет'
								subTitle='Добавьте значение и настройте его'
								add={add}
							/>
							:
							<AddButton color='saveVariableColor' onClick={add} />
					}
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>Сохранить</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 239px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default SaveVariablesNodeSettings