import Dataflow from './containers/dataflow'
import Login from './containers/login'

const routes = [
	{
		exact: true,
		path: '/',
		Component: Dataflow,
		protected: true
	},
	{
		exact: true,
		path: '/login',
		Component: Login
	},
	{
		path: '*',
		Component: Login
	}
]

export default routes