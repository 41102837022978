import React from 'react'
import { Box, Typography } from '@mui/material'

const TooltipTitle = ({ title }) => (
	<Box sx={styles.container}>
		<Typography variant='body2' color='secondary.light'>{title}</Typography>
	</Box>
)

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	}
}

export default TooltipTitle