import React from 'react'
import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

const VariableItem = ({ title, divider, source, destination, saveGlobally, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>

		<Box sx={styles.container}>
			<Box sx={styles.checkbox}>
				<Checkbox checked={saveGlobally} onChange={e => valueChanged('saveGlobally', e.target.checked)} />
				<Typography variant='body2'>Глоб. сейв</Typography>
			</Box>

			<Box sx={styles.field}>
				<TextField
					fullWidth
					placeholder='Исходное значение'
					value={source}
					onChange={e => valueChanged('source', e.target.value)}
				/>
			</Box>

			<Box sx={styles.field}>
				<TextField
					fullWidth
					placeholder='Значение назначения'
					value={destination}
					onChange={e => valueChanged('destination', e.target.value)}
				/>
			</Box>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon} />
			</Box>
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	checkbox: {
		width: 114,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	field: {
		width: 'calc(50% - 95px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	}
}

export default VariableItem