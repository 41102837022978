import React, { createContext, useContext, useState } from 'react'

const Context = createContext([null, (_) => { }])

export const Provider = ({ children }) => {
	const [type, setType] = useState(null)

	return (
		<Context.Provider value={[type, setType]}>
			{children}
		</Context.Provider>
	)
}

export default Context

export const useDnD = () => {
	return useContext(Context)
}