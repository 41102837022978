import React, { useEffect, useRef, useState } from 'react'
import { Box, List, ListItem, ListItemButton, Popover, Typography } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

const Dropdown = ({ placeholder = '', label, selected, items, onChange, container, box }) => {
	const ref = useRef(null)
	const [width, setWidth] = useState(0)
	const [anchor, setAnchor] = useState(null)
	const [item, setItem] = useState(null)

	useEffect(() => {
		setItem(items.find(i => i.id === selected) || null)
	}, [selected, items])

	useEffect(() => {
		setWidth(ref.current.offsetWidth)
		const getwidth = () => { setWidth(ref.current.offsetWidth) }
		window.addEventListener("resize", getwidth)
		return () => window.removeEventListener("resize", getwidth)
	}, [])

	const onClick = id => {
		onChange(id)
		setAnchor(null)
	}

	return <>
		{label && <Typography variant='body2' color='text.primary' fontWeight='bold'>{label}</Typography>}
		<Box sx={{ ...styles.container, ...container }} ref={ref}>
			<Box onClick={e => { setAnchor(e.currentTarget) }} sx={{ ...styles.box, ...box }}>
				<Typography color={item ? 'text.heading': 'primary.disabled'} sx={styles.title} variant='body2' noWrap>{item ? item.name : placeholder}</Typography>
				<Box sx={styles.iconContainer}>
					{anchor ? <KeyboardArrowUp sx={styles.icon} /> : <KeyboardArrowDown sx={styles.icon} />}
				</Box>
			</Box>
			<Popover
				keepMounted
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				anchorEl={anchor}
				open={!!anchor}
				onClose={() => { setAnchor(null) }}
			>
				<List sx={{ ...styles.list, width }}>
					{
						items.map((it, key) => (
							<ListItem key={key} disablePadding sx={it.id === selected ? styles.itemSelected : styles.item}>
								<ListItemButton onClick={() => { onClick(it.id) }} sx={styles.button}>
									<Typography color='text.heading' variant='body2' noWrap>{it.name}</Typography>
								</ListItemButton>
							</ListItem>
						))
					}
				</List>
			</Popover>
		</Box>
	</>
}

const styles = {
	container: {
		display: 'flex',
		width: '100%'
	},
	box: {
		cursor: 'pointer',
		border: theme => `1px solid ${theme.palette.primary.divider}`,
		borderRadius: '12px',
		width: '100%',
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		width: '-webkit-fill-available',
		px: 1.5,
		py: 1.375,
	},
	iconContainer: {
		height: 40,
		width: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 18,
		color: 'primary.gray'
	},
	list: {
		maxHeight: 200,
		overflow: 'auto',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		'&-ms-overflow-style:': {
			display: 'none',
		},
	},
	item: {
		backgroundColor: 'secondary.light',
		height: 40
	},
	itemSelected: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		height: 40
	},
	button: {
		height: 40
	}
}

export default Dropdown