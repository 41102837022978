import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import {
	ReactFlow,
	ReactFlowProvider,
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	Background,
	useReactFlow,
	getIncomers,
	getOutgoers,
	getConnectedEdges
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'

import Navigation from './navigation'
import { Provider, useDnD } from './context'
import StartNode from '../nodes/start'
import ApiNode from '../nodes/api'
import CheckParamsNode from '../nodes/check-params'
import CheckConditionsNode from '../nodes/check-conditions'
import ChatAINode from '../nodes/chat-ai'
import FilterNode from '../nodes/filter'
import MapperNode from '../nodes/mapper'
import RunAPINode from '../nodes/run-api'
import ResultNode from '../nodes/result'
import SaveVariablesNode from '../nodes/save-variables'
import Settings from './settings'
import { arraysEqual, checkEdges, initialData } from '../../helpers/utils'
import CustomControl from './custom-control'

const nodeTypes = {
	start: StartNode,
	api: ApiNode,
	checkParams: CheckParamsNode,
	runScenario: RunAPINode,
	checkConditions: CheckConditionsNode,
	mapper: MapperNode,
	filter: FilterNode,
	save_variables: SaveVariablesNode,
	getResult: ResultNode,
	chatAI: ChatAINode
}

const Dataflow = ({ scenarios, saving, error, message, toast, saveDataflow }) => {
	const [nodeId, setNodeId] = useState(null)
	const [nodes, setNodes] = useState([])
	const [edges, setEdges] = useState([])
	const [disabled, setDisabled] = useState(false)
	const [lastSelected, setLastSelected] = useState([null])
	const [anchorElSettings, setAnchorElSettings] = useState(null)
	const reactFlowWrapper = useRef(null)
	const { screenToFlowPosition } = useReactFlow()
	const [type] = useDnD()

	useEffect(() => {
		const scenario = [...scenarios].findLast(sc => sc.name === 'init')
		try {
			setNodes(JSON.parse(JSON.stringify(scenario?.parameters?.dataflow_data?.nodes || [])))
			setEdges(JSON.parse(JSON.stringify(scenario?.parameters?.dataflow_data?.edges || [])))
		} catch {}
	}, [scenarios])

	useEffect(() => {
		if (error)
			toast(error, { type: 'error' })
	}, [error, toast])

	useEffect(() => {
		if (message)
			toast(message, { type: 'success' })
	}, [message, toast])

	const onNodesChange = useCallback(changes => {
		if (disabled) return
		setNodes(nds => applyNodeChanges(changes, nds))
	}, [disabled, setNodes])

	const onNodesDelete = useCallback(deleted => {
		if (disabled) return
		setEdges(deleted.reduce((acc, node) => {
			const incomers = getIncomers(node, nodes, edges)
			const outgoers = getOutgoers(node, nodes, edges)
			const connectedEdges = getConnectedEdges([node], edges)

			return [
				...acc.filter(edge => !connectedEdges.includes(edge)),
				...incomers.flatMap(({ id: source }) =>
					outgoers.map(({ id: target }) => ({
						id: `${source}->${target}`,
						source,
						target,
					}))
				)
			]
		}, edges))
	}, [disabled, nodes, edges])

	const onEdgesChange = useCallback(changes => {
		if (disabled) return
		setEdges(eds => applyEdgeChanges(changes, eds))
	}, [disabled, setEdges])

	const onDrop = useCallback(evt => {
		evt.preventDefault()
		if (disabled || !type) return

		if (type === 'start' && nodes.find(node => node.type === 'start')) {
			toast('Можно добавить только один "Стартовый сценарий"', { type: 'error' })
			return
		}

		const position = screenToFlowPosition({ x: evt.clientX, y: evt.clientY })
		setNodes(nds => nds.concat({ id: uuidv4(), type, position, data: initialData(type) }))
	}, [disabled, nodes, screenToFlowPosition, toast, type])

	const onDragOver = useCallback(evt => {
		evt.preventDefault()
		evt.dataTransfer.dropEffect = 'move'
	}, [])

	const onSelectionChange = useCallback(({ nodes: nds }) => {
		const lastS = nds.map(nd => nd.id)
		if (arraysEqual(lastS, lastSelected)) return
		setLastSelected([...lastS])

		if (nodeId) {
			const node = nodes.find(node => node.id === nodeId)
			if (!node || (node.selected && node.data.focused))
				return

			setNodes([...nodes.map(node => ({ ...node, selected: node.id === nodeId, data: { ...node.data, focused: node.id === nodeId } }))])
		} else if (nds.length === 0) {
			if (nodes.filter(node => !node.selected && node.data.focused).length === nodes.length)
				return

			setNodes([...nodes.map(node => ({ ...node, selected: false, data: { ...node.data, focused: true } }))])
		} else {
			const a = nds.map(node => node.id)
			const b = nodes.filter(node => node.data.focused).map(node => node.id)

			if (arraysEqual(a, b)) return

			setNodes([...nodes.map(node => ({
				...node,
				selected: a.includes(node.id),
				data: {
					...node.data,
					focused: a.includes(node.id)
				}
			}))])
		}
	}, [lastSelected, nodeId, nodes])

	const onNodeClick = useCallback((event, node) => {
		if (disabled || event.ctrlKey) return

		setNodeId(node?.id)
		onSelectionChange({ nodes: [node] })
	}, [disabled, onSelectionChange])

	const onConnect = useCallback(params => {
		if (disabled) return
		const source = nodes.find(node => node.id === params.source)
		const target = nodes.find(node => node.id === params.target)

		setEdges(eds => {
			const error = checkEdges(source, target, eds, params)
			if (error) {
				toast(error, { type: 'error' })
				return eds
			}

			return addEdge(params, eds)
		})
	}, [disabled, nodes, toast])

	const save = useCallback(() => {
		const startNode = nodes.find(node => node.type === 'start')
		if (!startNode) {
			toast('Вы не добавили стартовый компонент.', { type: 'error' })
			return
		}

		saveDataflow([...nodes], [...edges], [...scenarios])
	}, [edges, nodes, saveDataflow, scenarios, toast])

	const exportData = useCallback(() => {
		setAnchorElSettings(null)
		const data = JSON.stringify({ nodes, edges }, null, 2)
		const blob = new Blob([data], { type: 'application/json' })
		const href = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.href = href
		link.download = `integration-${new Date().toISOString()}.json`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	}, [nodes, edges])

	const importData = useCallback(event => {
		setAnchorElSettings(null)
		event.preventDefault()
		const reader = new FileReader()
		reader.onload = async e => {
			try {
				const data = JSON.parse(e.target.result)
				if (Array.isArray(data.nodes) && Array.isArray(data.edges)) {
					setNodes(data.nodes)
					setEdges(data.edges)
				} else {
					toast(`Что-то пошло не так. Ошибка: блоки недействительны.`, { type: 'error' })
				}
			} catch (err) {
				toast(`Что-то пошло не так. Ошибка: ${err.message}`, { type: 'error' })
			}
			event.target.value = ''
		}
		reader.readAsText(event.target.files[0])
	}, [toast])

	const copyToClipboard = useCallback(async () => {
		setAnchorElSettings(null)
		await navigator.clipboard.writeText(JSON.stringify({ nodes, edges }, null, 2))
		toast('Блоки успешно скопированы в буфер обмена.', { type: 'success' })
	}, [edges, nodes, toast])

	const pasteFromClipboard = useCallback(async () => {
		setAnchorElSettings(null)
		try {
			const data = JSON.parse(await navigator.clipboard.readText())
			if (Array.isArray(data.nodes) && Array.isArray(data.edges)) {
				setNodes(data.nodes)
				setEdges(data.edges)
				toast('Блоки успешно импортированы из буфера обмена.', { type: 'success' })
			} else {
				toast(`Что-то пошло не так. Ошибка: блоки недействительны.`, { type: 'error' })
			}
		} catch (err) {
			toast(`Что-то пошло не так. Ошибка: ${err.message}`, { type: 'error' })
		}
	}, [toast])

	return (
		<Box component='main' sx={styles.main}>
			<Box sx={styles.buttonsContainer}>
				<Button
					variant='contained'
					sx={styles.button}
					disabled={saving}
					onClick={save}
				>
					{saving ? 'Сохраняется ...' : 'Сохранить'}
				</Button>

				<Box>
					<Button
						variant='contained'
						color='secondary'
						sx={styles.button}
						disabled={saving}
						onClick={e => { setAnchorElSettings(e.currentTarget) }}
					>
						Настройки
					</Button>
					<Popover
						keepMounted
						sx={styles.popover}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						transformOrigin={{ vertical: 'top', horizontal: 50 }}
						anchorEl={anchorElSettings}
						open={!!anchorElSettings}
						onClose={() => { setAnchorElSettings(null) }}
					>
						<List sx={styles.paper}>
							<ListItem disablePadding>
								<ListItemButton onClick={exportData}>
									<ListItemText primary='Экспортировать' sx={styles.listItem} />
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<input type='file' id='upload-file' accept='.json' style={styles.input} onChange={importData} />
								<label htmlFor='upload-file' style={styles.w100}>
									<ListItemButton>
										<ListItemText primary='Импортировать' sx={styles.listItem} />
									</ListItemButton>
								</label>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={copyToClipboard}>
									<ListItemText primary='Копировать' sx={styles.listItem} />
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={pasteFromClipboard}>
									<ListItemText primary='Вставить' sx={styles.listItem} />
								</ListItemButton>
							</ListItem>
						</List>
					</Popover>
				</Box>
			</Box>

			{saving && <Box sx={styles.loader}>
				<CircularProgress />
			</Box>}
			<Box sx={styles.navigation}>
				<Navigation save={save} saving={saving} />
			</Box>
			<Box sx={styles.container} ref={reactFlowWrapper}>
				<ReactFlow
					nodes={nodes}
					edges={edges.map(edge => ({ ...edge, style: { strokeWidth: 4 } }))}
					onNodesChange={onNodesChange}
					onNodesDelete={onNodesDelete}
					onNodeClick={onNodeClick}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					nodeTypes={nodeTypes}
					connectionLineStyle={styles.edge}
					onSelectionChange={onSelectionChange}
					fitView
					onDrop={onDrop}
					onDragOver={onDragOver}
					edgesReconnectable={!disabled}
					edgesFocusable={!disabled}
					nodesDraggable={!disabled}
					nodesConnectable={!disabled}
					nodesFocusable={!disabled}
					elementsSelectable={!disabled}
				>
					<CustomControl disabled={disabled} setDisabled={setDisabled} />
					<Background variant='dots' gap={12} size={1} />
				</ReactFlow>
			</Box>

			<Settings
				toast={toast}
				open={!!nodeId}
				node={nodes.find(n => n.id === nodeId) || null}
				nodes={nodes}
				update={node => {
					setNodes([...nodes.map(n => n.id === node.id ? node : n)])
					setNodeId(null)
				}}
				cancel={() => { setNodeId(null) }}
			/>
		</Box>
	)
}

const styles = {
	main: {
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		minHeight: '100%'
	},
	navigation: {
		position: 'fixed',
		top: 0,
		width: 280,
		height: '100vh',
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRight: theme => `1px solid ${theme.palette.primary.border}`
	},
	container: {
		position: 'fixed',
		top: 0,
		left: 280,
		width: 'calc(100vw - 280px)',
		height: '100vh'
	},
	edge: {
		stroke: '#212529'
	},
	loader: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		backgroundColor: theme => `${theme.palette.primary.border}7A`,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000
	},
	buttonsContainer: {
		position: 'fixed',
		right: 96,
		top: 16,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
		zIndex: 3
	},
	button: {
		borderRadius: '100px',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
		ml: 3
	},
	popover: {
		mt: 1
	},
	paper: {
		width: 150
	},
	listItem: {
		'& > .MuiTypography-root': {
			fontSize: 14
		}
	},
	input: {
		display: 'none'
	},
	w100: {
		width: '100%'
	}
}

const Flow = props => (
	<ReactFlowProvider>
		<Provider>
			<Dataflow {...props} />
		</Provider>
	</ReactFlowProvider>
)

export default Flow