import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Dataflow from '../../components/dataflow'
import { save } from '../../actions/scenarios'

const mapStateToProps = state => {
	return {
		scenarios: state.scenarios.scenarios,
		saving: state.scenarios.saving,
		error: state.scenarios.error,
		message: state.scenarios.message
	}
}

const mapDispatchToProps = dispatch => {
	return {
		saveDataflow: (nodes, edges, scenarios) => dispatch(save(nodes, edges, scenarios))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dataflow))