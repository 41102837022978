import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

const DialogTitle = ({ title, description, cancel }) => (
	<Box sx={styles.main} className='drag-handle'>
		<Box sx={styles.title}>
			<Typography variant='title' fontWeight='bold' noWrap>{title}</Typography>
			<Typography variant='body2' color='primary.gray' noWrap>{description}</Typography>
		</Box>
		<IconButton onClick={cancel} sx={styles.button}>
			<Close sx={styles.icon} />
		</IconButton>
	</Box>
)

const styles = {
	main: {
		width: '100%',
		p: 2,
		cursor: 'move',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	title: {
		width: 'calc(100% - 56px)'
	},
	button: {
		border: theme => `1px solid ${theme.palette.primary.divider}`,
		height: 40,
		width: 40
	},
	icon: {
		color: theme => theme.palette.primary.gray,
		fontSize: 21
	}
}

export default DialogTitle