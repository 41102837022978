import { ACTION_TYPES } from '../actions/scenarios'

const initialState = {
	scenarios: [],
	fetching: false,
	fetched: false,
	saving: false,
	message: '',
	error: ''
}

const scenarios = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_SCENARIOS_INIT:
			return {
				...state,
				scenarios: [],
				fetching: true,
				fetched: false
			}
		case ACTION_TYPES.FETCH_SCENARIOS_SUCCESS:
			return {
				...state,
				scenarios: JSON.parse(JSON.stringify(action.data)),
				fetching: false,
				fetched: true
			}
		case ACTION_TYPES.FETCH_SCENARIOS_FAIL:
			return {
				...state,
				error: action.error,
				fetching: false,
				fetched: false
			}
		case ACTION_TYPES.SAVE_DATAFLOW_INIT:
			return {
				...state,
				saving: true
			}
		case ACTION_TYPES.SAVE_DATAFLOW_SUCCESS:
			return {
				...state,
				saving: false,
				message: action.message
			}
		case ACTION_TYPES.SAVE_DATAFLOW_FAIL:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case ACTION_TYPES.RESET_ERROR:
			return {
				...state,
				error: ''
			}
		case ACTION_TYPES.RESET_MESSAGE:
			return {
				...state,
				message: ''
			}
		case ACTION_TYPES.RESET_STATE:
			return {
				...state,
				scenarios: [],
				fetching: false,
				fetched: false,
				message: '',
				error: ''
			}
		default:
			return state
	}
}

export default scenarios