import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Navbar from '../../components/layout/navbar'
import { logout } from '../../actions/scenarios'

const mapStateToProps = () => {
	return {}
}

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logout())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))