import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import ready from 'when-dom-ready'

import './assets/index.css'
import Layout from './containers/layout'
import reducer from './reducers'

axios.defaults.baseURL = process.env.REACT_APP_URL
axios.defaults.headers['Content-Type'] = 'application/json'
const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f

ready()
	.then(() => {
		const store = configureStore({
			reducer,
			preloadedState: {},
			devTools,
			middleware: getDefaultMiddleware => getDefaultMiddleware()
		})
		const root = ReactDOM.createRoot(document.getElementById('root'))
		root.render(<Provider store={store}><Layout /></Provider>)
	})