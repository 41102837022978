import React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

const KeyValueItem = ({ title, divider, name, nameChanged, value, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>
		<Box sx={styles.container}>
			<TextField
				sx={styles.textField}
				placeholder='Название'
				value={name}
				onChange={e => nameChanged(e.target.value)}
			/>

			<TextField
				sx={styles.textField}
				placeholder='Значение'
				value={value}
				onChange={e => valueChanged(e.target.value)}
			/>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon}/>
			</Box>
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	textField: {
		width: 'calc(50% - 30px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	}
}

export default KeyValueItem