import React from 'react'
import { Button } from '@mui/material'
import { AddCircleRounded } from '@mui/icons-material'

const AddButton = ({ onClick, color }) => (
	<Button
		variant='contained'
		onClick={onClick}
		sx={{
			...styles.button,
			backgroundColor: theme => theme.palette.primary[color],
			'&:hover': {
				boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
				backgroundColor: theme => `${theme.palette.primary[color]}bf`
			},
			'&:active': {
				boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
				backgroundColor: theme => theme.palette.primary[color]
			}
		}}
	>
		Добавить <AddCircleRounded sx={styles.icon} />
	</Button>
)

const styles = {
	button: {
		width: 120,
		height: 32,
		color: theme => theme.palette.primary.contrastText,
		fontSize: 14,
		lineHeight: '16px',
		borderRadius: '100px',
		boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
		mt: 2,
		mb: 5
	},
	icon: {
		fontSize: 16,
		margin: '0 0 0 8px !important'
	}
}

export default AddButton