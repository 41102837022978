import React from 'react'
import { Box, Typography } from '@mui/material'

const NodeBody = ({ title, Icon, color }) => (
	<Box sx={{ ...styles.main, backgroundColor: `${color}80` }} >
		{Icon && <Icon sx={styles.icon} />}
		{title && <Typography variant='h6' textAlign='center' color='primary.contrastText'>{title}</Typography>}
	</Box>
)

const styles = {
	main: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: '10px',
		p: 1
	},
	icon: {
		fontSize: 20,
		color: theme => theme.palette.primary.contrastText
	}
}

export default NodeBody