import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import Tabs from '../custom/tabs'
import { componentsInfo } from '../../helpers/constants'
import NoData from '../custom/no-data'
import DialogTitle from '../custom/dialog-title'
import WebhookSettings from './webhook-settings'
import ListItem from '../custom/list-item'
import AddButton from '../custom/add-button'
import ConditionSettings from './condition-settings'
import ActionItem from '../custom/action-item'
import TextArea from '../custom/text-area'

const tabs = ['Вебхуки', 'Условия', 'Действия']

const ApiNodeSettings = ({ toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [name, setName] = useState('')
	const [webhooks, setWebhooks] = useState([])
	const [conditions, setConditions] = useState([])
	const [actions, setActions] = useState([])
	const [status, setStatus] = useState({})
	const [tab, setTab] = useState(0)

	useEffect(() => {
		if (node?.id !== id) {
			setName(node?.data?.name || '')
			setWebhooks(node?.data?.webhooks || [])
			setConditions(node?.data?.conditions || [])
			setActions(node?.data?.actions || [])
			setId(node?.id)
		}
	}, [id, node])

	const add = () => {
		setActions(acs => [...acs, { id: uuidv4(), webhookId: '' }])
	}

	const edit = (idx, key, value) => {
		setActions(acs => [...acs.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = (type, idx) => {
		if (type === 'webhook') {
			setWebhooks(whs => [...whs.filter((_, i) => i !== idx)])
		} else if (type === 'condition') {
			setConditions(cnds => [...cnds.filter((_, i) => i !== idx)])
		} else if (type === 'action') {
			setActions(acs => [...acs.filter((_, i) => i !== idx)])
		}
	}

	const onWebhookSave = useCallback(webhook => {
		if (status.action === 'webhook') {
			if (status.type === 'add')
				setWebhooks(whs => [...whs, { id: uuidv4(), ...webhook }])
			else if (status.type === 'edit')
				setWebhooks(whs => [...whs.map((wh, key) => key === status.idx ? ({ ...wh, ...webhook }) : wh)])

			setStatus({})
		}
	}, [status])

	const onConditionSave = useCallback(condition => {
		if (status.action === 'condition') {
			if (status.type === 'add')
				setConditions(cnds => [...cnds, { id: uuidv4(), ...condition }])
			else if (status.type === 'edit')
				setConditions(cnds => [...cnds.map((cnd, key) => key === status.idx ? ({ ...cnd, ...condition }) : cnd)])

			setStatus({})
		}
	}, [status])

	const save = useCallback(() => {
		if (!name) {
			toast('Пожалуйста введите название', { type: 'error' })
			return
		}

		for (const condition of conditions) {
			if (!webhooks.find(w => w.id === condition.webhookId) || !webhooks.find(w => w.id === condition.conditionWebhookId)) {
				toast('Пожалуйста выберите вебхук в условии (condition)', { type: 'error' })
				return
			}
		}

		for (const action of actions) {
			if (!webhooks.find(w => w.id === action.webhookId)) {
				toast('Пожалуйста выберите вебхук в действии (action)', { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, name, webhooks, conditions, actions } })
	}, [actions, conditions, name, node, toast, update, webhooks])

	return (
		<Box sx={styles.main}>
			{
				!status.action && <>
					<DialogTitle
						title={componentsInfo[node.type].title}
						description={componentsInfo[node.type].description}
						cancel={cancel}
					/>

					<Box sx={styles.row}>
						<TextArea
							placeholder='Введите описание компонента'
							rows={3}
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</Box>

					<Box sx={styles.divider} />

					<Box sx={styles.tabs}>
						<Tabs tabs={tabs} width={componentsInfo[node.type].width - 32} value={tab} setValue={setTab} />
					</Box>

					<Box sx={styles.body}>
						{
							((tab === 0 && webhooks.length === 0) || (tab === 1 && conditions.length === 0) || (tab === 2 && actions.length === 0))
								?
								<NoData
									color='apiColor1'
									title='Тут пока ничего нет'
									subTitle={`Добавьте ${tabs[tab].toLocaleLowerCase()} и настройте`}
									add={() => {
										if (tab === 0)
											setStatus({ action: 'webhook', type: 'add' })
										else if (tab === 1)
											setStatus({ action: 'condition', type: 'add' })
										else
											add()
									}}
								/>
								:
								<>
									{tab === 0
										?
										webhooks.map((webhook, key) => (
											<ListItem
												key={key}
												divider={key !== 0}
												title={webhook.name}
												onEdit={() => { setStatus({ action: 'webhook', type: 'edit', idx: key }) }}
												onDelete={() => { remove('webhook', key) }}
											/>
										))
										:
										tab === 1
											?
											conditions.map((condition, key) => (
												<ListItem
													key={key}
													divider={key !== 0}
													title={condition.name}
													onEdit={() => { setStatus({ action: 'condition', type: 'edit', idx: key }) }}
													onDelete={() => { remove('condition', key) }}
												/>
											))
											:
											actions.map((action, key) => (
												<ActionItem
													key={key}
													divider={key !== 0}
													title={`Действие #${key + 1}`}
													{...action}
													webhooks={webhooks.map(w => ({ id: w.id, name: w.name }))}
													valueChanged={(k, v) => { edit(key, k, v) }}
													onDelete={() => { remove('action', key) }}
												/>
											))
									}

									<AddButton
										color='apiColor1'
										onClick={() => {
											if (tab === 0)
												setStatus({ action: 'webhook', type: 'add' })
											else if (tab === 1)
												setStatus({ action: 'condition', type: 'add' })
											else
												add()
										}}
									/>
								</>
						}
					</Box>

					<Box sx={styles.buttonsContainer}>
						<Button variant='contained' color='primary' sx={styles.button} onClick={save}>Сохранить</Button>
					</Box>
				</>
			}

			{status.action === 'webhook' && (
				<WebhookSettings
					webhook={status.type === 'add' ? null : webhooks[status.idx]}
					toast={toast}
					onClose={() => { setStatus({}) }}
					save={onWebhookSave}
				/>
			)}

			{status.action === 'condition' && (
				<ConditionSettings
					condition={status.type === 'add' ? null : conditions[status.idx]}
					webhooks={webhooks}
					toast={toast}
					onClose={() => { setStatus({}) }}
					save={onConditionSave}
				/>
			)}
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		my: 2
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	},
	tabs: {
		width: '100%',
		px: 2
	},
	body: {
		height: 'calc(100% - 216px)',
		overflowY: 'auto',
		px: 2,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}
}

export default ApiNodeSettings