import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import Dropdown from '../custom/dropdown'
import KeyValueItem from '../custom/key-value-item'
import NoData from '../custom/no-data'
import RequiredEntryItem from '../custom/required-entry-item'
import TextButton from '../custom/text-button'
import { contentTypes, methods } from '../../helpers/constants'
import ResultItem from '../custom/result-item'
import AddButton from '../custom/add-button'
import TextArea from '../custom/text-area'

const WebhookSettings = ({ webhook, toast, onClose, save }) => {
	const [name, setName] = useState('')
	const [url, setUrl] = useState('')
	const [method, setMethod] = useState('GET')
	const [contentType, setContentType] = useState('application/json')
	const [headers, setHeaders] = useState([{ 'key': 'Content-Type', 'value': 'application/json' }])
	const [cookies, setCookies] = useState([])
	const [body, setBody] = useState([])
	const [queryParams, setQueryParams] = useState([])
	const [requiredEntry, setRequiredEntry] = useState([])
	const [responseSchema, setResponseSchema] = useState(null)
	const [result, setResult] = useState([])
	const [tab, setTab] = useState('header')
	const [items, setItems] = useState([])
	const theme = useTheme()

	useEffect(() => {
		setName(webhook?.name || '')
		setUrl(webhook?.url || '')
		setMethod(webhook?.method || 'GET')
		setContentType(webhook?.contentType || 'application/json')
		setHeaders(webhook?.headers || [{ 'key': 'Content-Type', 'value': 'application/json' }])
		setCookies(webhook?.cookies || [])
		setBody(webhook?.body || [])
		setQueryParams(webhook?.queryParams || [])
		setRequiredEntry(webhook?.requiredEntry || [])
		setResponseSchema(webhook?.responseSchema || null)
		setResult(webhook?.result || [])
	}, [webhook])

	useEffect(() => {
		setItems([
			{ title: 'Заголовки', type: 'header', value: headers, subTitle: 'Заголовок' },
			{ title: 'Куки', type: 'cookie', value: cookies, subTitle: 'Куки' },
			{ title: 'Тело', type: 'body', value: body, subTitle: 'Тело' },
			{ title: 'Параметры', type: 'param', value: queryParams, subTitle: 'Параметр' },
			{ title: 'Записи', type: 'entry', value: requiredEntry },
			{ title: 'Схема ответа', type: 'schema' },
			{ title: 'Сохранение', type: 'result', value: result }
		])
	}, [body, cookies, headers, queryParams, requiredEntry, responseSchema, result])

	const add = type => {
		if (type === 'cookie')
			setCookies(cks => [...cks, { key: '', value: '' }])
		else if (type === 'header')
			setHeaders(hdrs => [...hdrs, { key: '', value: '' }])
		else if (type === 'body')
			setBody(bd => [...bd, { key: '', value: '' }])
		else if (type === 'param')
			setQueryParams(qps => [...qps, { key: '', value: '' }])
		else if (type === 'entry')
			setRequiredEntry(res => [...res, { type: 'queryParams', nullable: false, sourceName: '', destinationPath: '', destinationType: 'string' }])
		else if (type === 'result')
			setResult(res => [...res, { from: '', type: 'string', variable: '' }])
	}

	const edit = (type, idx, key, value) => {
		if (type === 'cookie') {
			setCookies(cks => [...cks.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'header') {
			setHeaders(hdrs => [...hdrs.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'body') {
			setBody(bd => [...bd.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'param') {
			setQueryParams(qps => [...qps.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'entry') {
			setRequiredEntry(res => [...res.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		} else if (type === 'result') {
			setResult(res => [...res.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
		}
	}

	const remove = (type, idx) => {
		if (type === 'cookie') {
			setCookies(cks => [...cks.filter((_, i) => i !== idx)])
		} else if (type === 'header') {
			setHeaders(hdrs => [...hdrs.filter((_, i) => i !== idx)])
		} else if (type === 'body') {
			setBody(bd => [...bd.filter((_, i) => i !== idx)])
		} else if (type === 'param') {
			setQueryParams(qps => [...qps.filter((_, i) => i !== idx)])
		} else if (type === 'entry') {
			setRequiredEntry(res => [...res.filter((_, i) => i !== idx)])
		} else if (type === 'result') {
			setResult(res => [...res.filter((_, i) => i !== idx)])
		}
	}

	const onSave = useCallback(() => {
		if (!name) {
			toast('Пожалуйста введите название', { type: 'error' })
			return
		}

		if (!url) {
			toast('Пожалуйста введите URL', { type: 'error' })
			return
		}

		if (responseSchema) {
			try {
				JSON.parse(responseSchema)
			} catch (err) {
				console.log('err: ', err)
				toast(`Схема ответа (Response Schema): Объект JSON недействителен. Ошибка: ${err?.message}`, { type: 'error' })
				return
			}
		}

		save({
			name,
			url,
			method,
			contentType,
			headers,
			cookies,
			body,
			queryParams,
			requiredEntry,
			responseSchema,
			result
		})
	}, [body, contentType, cookies, headers, method, name, queryParams, requiredEntry, responseSchema, result, save, toast, url])

	return <Box sx={styles.main}>
		<DialogTitle
			title={webhook?.id ? 'Редактирование вебхука' : 'Добавление вебхука'}
			description='Автоматическая отправка данных при событии'
			cancel={onClose}
		/>

		<Box sx={styles.row}>
			<Box sx={styles.w50}>
				<Typography variant='h6' fontWeight='bold' sx={styles.title} noWrap>Название</Typography>
				<TextField fullWidth placeholder='Вебхук #1' value={name} onChange={e => setName(e.target.value)} />
			</Box>
			<Box sx={styles.w50}>
				<Typography variant='h6' fontWeight='bold' sx={styles.title} noWrap>URL</Typography>
				<TextField fullWidth placeholder='https://' value={url} onChange={e => setUrl(e.target.value)} />
			</Box>
		</Box>

		<Box sx={styles.row}>
			<Box sx={styles.w50}>
				<Typography variant='h6' fontWeight='bold' sx={styles.title} noWrap>Запрос</Typography>
				<Dropdown selected={method} items={methods} onChange={item => setMethod(item)} />
			</Box>
			<Box sx={styles.w50}>
				<Typography variant='h6' fontWeight='bold' sx={styles.title} noWrap>Тип контента</Typography>
				<Dropdown selected={contentType} items={contentTypes} onChange={item => setContentType(item)} />
			</Box>
		</Box>

		<Box sx={styles.divider} />

		<Box sx={styles.middle}>
			<Box sx={styles.items}>
				{
					items.map((item, key) => (
						<TextButton
							key={key}
							title={item.title}
							active={tab === item.type}
							color={theme.palette.primary.apiColor1}
							onClick={() => { setTab(item.type) }}
						/>
					))
				}
			</Box>
			<Box sx={styles.body}>
				{
					tab === 'schema'
						?
						<Box sx={styles.schema}>
							<TextArea
								placeholder='{ "type": "object", "required": ["result"], "properties": { "result": { "type": "boolean" } } }'
								rows={15}
								value={responseSchema}
								onChange={e => setResponseSchema(e.target.value)}
							/>
						</Box>
						:
						tab === 'entry'
							?
							requiredEntry.map((item, key) => (
								<RequiredEntryItem
									key={key}
									title={`Запись #${key + 1}`}
									divider={key !== 0}
									{...item}
									valueChanged={(k, v) => { edit(tab, key, k, v) }}
									onDelete={() => { remove(tab, key) }}
								/>
							))
							:
							tab === 'result'
								?
								result.map((item, key) => (
									<ResultItem
										key={key}
										title={`Сохранение переменной #${key + 1}`}
										divider={key !== 0}
										{...item}
										valueChanged={(k, v) => { edit(tab, key, k, v) }}
										onDelete={() => { remove(tab, key) }}
									/>
								))
								:
								items.map((item, key) => (
									item.type === tab
										?
										item.value.map((val, idx) => (
											<KeyValueItem
												key={idx}
												title={`${item.subTitle} #${idx + 1}`}
												divider={idx !== 0}
												name={val.key}
												nameChanged={value => { edit(tab, idx, 'key', value) }}
												value={val.value}
												valueChanged={value => { edit(tab, idx, 'value', value) }}
												onDelete={() => { remove(tab, idx) }}
											/>
										))
										:
										<React.Fragment key={key} />
								))
				}

				{
					tab !== 'schema'
						?
						(
							items.find(item => item.type === tab)?.value?.length === 0
								?
								<NoData
									color='apiColor1'
									title='Тут пока ничего нет'
									subTitle={`Добавьте ${items.find(item => item.type === tab).title.toLowerCase()} и настройте`}
									add={() => { add(tab) }}
								/>
								:
								<AddButton color='apiColor1' onClick={() => { add(tab) }} />
						)
						:
						<></>
				}
			</Box>
		</Box>

		<Box sx={styles.buttonsContainer}>
			<Button variant='contained' color='inherit' sx={styles.button} onClick={onClose}>
				<ArrowBack /> Назад
			</Button>
			<Button variant='contained' color='primary' sx={styles.button} onClick={onSave}>Сохранить</Button>
		</Box>
	</Box>
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		mb: 1.25
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		px: 2,
		mb: 2
	},
	w50: {
		width: 'calc(50% - 8px)'
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	button: {
		width: 'calc(50% - 4px)',
		borderRadius: '100px'
	},
	schema: {
		width: '100%',
		mt: 1
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 298px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		py: 1,
		px: 2
	}
}

export default WebhookSettings