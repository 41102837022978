import React from 'react'
import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

import Dropdown from './dropdown'
import { propTypes, requiredEntryTypes } from '../../helpers/constants'

const RequiredEntryItem = ({ title, divider, nullable, type, sourceName, destinationPath, destinationType, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>
		<Box sx={styles.container}>
			<Box sx={styles.checkbox}>
				<Checkbox checked={nullable} onChange={e => valueChanged('nullable', e.target.checked)} />
				<Typography variant='body2'>Nullable</Typography>
			</Box>
			<Box sx={styles.middle}>
				<Box sx={styles.top}>
					<Box sx={styles.half}>
						<Dropdown selected={type} items={requiredEntryTypes} onChange={item => valueChanged('type', item)} />
					</Box>
					<Box sx={styles.half}>
						<TextField
							fullWidth
							placeholder='Исходное значение'
							value={sourceName}
							onChange={e => valueChanged('sourceName', e.target.value)}
						/>
					</Box>
				</Box>
				<Box sx={styles.bottom}>
					<Box sx={styles.half}>
						<TextField
							fullWidth
							placeholder='Значение для сохранения'
							value={destinationPath}
							onChange={e => valueChanged('destinationPath', e.target.value)}
						/>
					</Box>
					<Box sx={styles.half}>
						<Dropdown
							selected={destinationType}
							items={propTypes}
							onChange={item => valueChanged('destinationType', item)}
						/>
					</Box>
				</Box>
			</Box>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon}/>
			</Box>
		</Box>
	</Box>
)


const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	checkbox: {
		width: 106,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	middle: {
		width: 'calc(100% - 166px)',
		display: 'flex',
		flexDirection: 'column'
	},
	top: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		pb: 2
	},
	bottom: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	half: {
		width: 'calc(50% - 8px)'
	},

	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	}
}

export default RequiredEntryItem