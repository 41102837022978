import { connect } from 'react-redux'

import Layout from '../../components/layout'

const mapStateToProps = state => {
	return {
		fetched: state.scenarios.fetched
	}
}

const mapDispatchToProps = () => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)