import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Navbar from '../../containers/layout/navbar'
import theme from '../../helpers/theme'
import routes from '../../routes'

const Layout = ({ fetched }) => {
	const renderComponents = (route, componentProps) => {
		if (!fetched) {
			return route.path !== '/login' ? <Redirect to='/login' /> : <route.Component toast={toast} />
		} else {
			if (['/login', '*'].includes(route.path))
				return <Redirect to='/' />
			else
				return <><Navbar/><route.Component {...componentProps} toast={toast} /></>
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<Switch>
					{routes.map((route, index) => {
						return <Route
							key={index}
							path={route.path}
							exact={route.exact}
							render={componentProps => renderComponents(route, componentProps)}
						/>
					})}
				</Switch>
			</BrowserRouter>
			<div className='toast-container'>
				<ToastContainer position='bottom-right' />
			</div>
		</ThemeProvider>
	)
}

export default Layout