import React from 'react'
import { Box, Typography } from '@mui/material'

const TextButton = ({ title, active, color, onClick }) => (
	<Box onClick={onClick} sx={{ ...styles.main, backgroundColor: active ? color : `${color}14` }} >
		<Typography variant='h6' color={active ? 'primary.contrastText' : color} noWrap>{title}</Typography>
	</Box>
)

const styles = {
	main: {
		mx: 0.5,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100px',
		padding: '10px 12px',
		cursor: 'pointer'
	}
}

export default TextButton